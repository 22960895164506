<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-card :loading="loading" width="850" elevation="3">
      <!-- <v-row>
        <v-col cols="10"></v-col>
        <v-specer></v-specer>
        <v-col cols="2">
          <v-btn text @click="print">
            <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
          </v-btn>
        </v-col>
      </v-row> -->

      <v-card-title class="ml-10 mt-5"> </v-card-title>

      <v-card-text>
        <v-row class="ml-10">
          <v-col cols="9" class="mt-7">
            <v-text
              style="font-size: 2.5rem ; color:#1E88E5;"
              
            >
              INVOICE
            </v-text>
          </v-col>

          <v-col cols="2">
            <v-img
              height="100%"
              :src="companyinfo.logo_url"
              alt="Rooster crowing"
            ></v-img>
          </v-col>
        </v-row>
        <div id="dataapp">
          <!-- <v-row class="ml-10 mt-10 d-flex-row">
            <v-col cols="3">
              <p class="font-weight-medium">Invoice Number:</p>
              <p>{{ invoiceinfo.id }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Payment status:</p>
              <p>{{ orderinfo.order_status }}</p>
            </v-col>
            <v-col cols="3">
              <p class="font-weight-medium">Date of Issue:</p>
              <p>{{ invoiceinfo.date }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Time:</p>
              <p>{{ invoiceinfo.time }}</p>
            </v-col>
          </v-row> -->

          <v-row class="ml-10 pa-0 mt-5">
            <v-col cols="3" class="py-0">
              <p class="font-weight-medium text-body-1 mb-0">Invoice Number</p>
            </v-col>
            <v-col cols="5" class="py-0">
              <p class="text-body-1 font-weight-medium mb-0">: {{ invoiceinfo.id }}</p>
            </v-col>
         <v-col cols="3" class="py-0 pr-0">
              <p class="font-weight-small text-body-1 mb-0 font-italic">Date of Issue: {{ invoiceinfo.date }}</p>
            </v-col>
          <v-col cols="1"></v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-medium text-body-1 mb-0">Payment Status</p>
            </v-col>
            <v-col cols="5" class="py-0">
              <p class="font-weight-medium text-body-1 mb-0">: {{ orderinfo.order_status }}</p>
            </v-col>
             <v-col cols="3" class="py-0 ">
              <p class="font-weight-small text-body-1 text-right mb-0 font-italic">Time: {{ invoiceinfo.time }}</p>
            </v-col>

            <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0 mt-5">Customer Name</p>
            </v-col>
            <v-col cols="5" class="py-0">
              <p class="text-body-1 mb-0 mt-5" >: N/A</p>
            </v-col>

            <v-col cols="2" class="py-0">
              <p class="text-body-1"></p>
            </v-col>

            <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0">Billing Address</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p class="text-body-1 mb-0">: N/A</p>
            </v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0">City</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p class="text-body-1 mb-0">: N/A</p>
            </v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0">Phone Number</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p class="text-body-1 mb-0">: N/A</p>
            </v-col>
          </v-row>
         <v-row>
           
         </v-row>
            <v-card min-height="480" elevation="0">
                <v-simple-table class="mx-12 my-5" dense >
                <template v-slot:default>
                  <thead >
                    <tr class="blue darken-1  " >
                      <th class="text-body-2 white--text ">Name</th>
                      <th class="text-body-2 white--text">Color</th>
                      <th class="text-body-2 white--text">Size</th>
                      <th class="text-body-2 white--text">Quantity</th>
                      <th class="text-body-2 white--text">Unit</th>
                      <th class="text-body-2 white--text">Unit Price</th>
                      <th class="text-body-2 white--text"> Discount</th>
                      <th class="text-body-2 white--text"> Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in orders" :key="item.product_name">
                      <td class="text-xs-right">{{ item.product_name }}</td>
                      <td class="text-xs-right">{{ item.product_color }}</td>
                      <td class="text-xs-right">{{ item.product_size }}</td>
                      <td class="text-xs-right">{{ item.product_unit }}</td>
                      <td class="text-xs-right">{{ item.total_quantity }}</td>
                      <td class="text-xs-right">{{ item.unit_price }}</td>
                      <td class="text-xs-right">{{ item.total_discount }}</td>
                      <td class="text-xs-right">{{ item.total_price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              

              <!-- <v-data-table
                dense
                :headers="headers"
                :items="orders"
                class="elevation-0 mx-12"
                hide-default-footer
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.product_name }}</td>
                  <td class="text-xs-right">{{ props.item.product_color }}</td>
                  <td class="text-xs-right">{{ props.item.product_size }}</td>
                  <td class="text-xs-right">{{ props.item.product_unit }}</td>
                  <td class="text-xs-right">{{ props.item.total_quantity }}</td>
                  <td class="text-xs-right">{{ props.item.unit_price }}</td>
                  <td class="text-xs-right">{{ props.item.total_discount }}</td>
                  <td class="text-xs-right">{{ props.item.total_price }}</td>
                </template>
              </v-data-table> -->
           
          <v-row>
            <v-col cols="7"> </v-col>

            <v-col cols="4">
              <div>
                <div
                  style="border-bottom: 1px solid"
                  class="d-flex flex-row align-center"
                >
                  <p class="mb-1 font-weight-medium ml-2">Sub Total</p>
                  <v-spacer></v-spacer>
                  <p class="mb-1 font-weight-medium mr-5">
                    {{ orderinfo.sub_total }}
                  </p>
                </div>
                <div
                  style="border-bottom: 1px solid"
                  class="d-flex flex-row align-center"
                >
                  <p class="mt-2 mb-2 font-weight-medium ml-2">Discount</p>
                  <v-spacer></v-spacer>
                  <p class="mt-2 mb-2 mr-5 font-weight-medium">
                    {{ orderinfo.pos_additional_discount }}
                  </p>
                </div>
                <div
                  style="border-bottom: 1px solid"
                  class="d-flex flex-row align-center"
                >
                  <p class="mt-2 mb-1 font-weight-medium ml-2">VAT</p>
                  <v-spacer></v-spacer>
                  <p class="mt-2 mb-1 font-weight-medium mr-5">
                    {{ orderinfo.vat }}
                  </p>
                </div>
                <div class="d-flex flex-row align-center">
                  <p class="mt-2 mb-1 font-weight-medium ml-2">Grand Total</p>
                  <v-spacer></v-spacer>
                  <p class="mt-2 mb-1 font-weight-medium mr-5">
                    {{ orderinfo.grand_total }}
                  </p>
                </div>
              </div>
              <!-- <v-col class="d-flex flex-row mt-0 pa-0">
                <p class="font-weight-medium text-right">Sub Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.sub_total }}</p>
              </v-col>

              <v-col class="d-flex flex-row mt-0 pa-0" style="padding-top: 0">
                <p class="font-weight-medium mt-0">Discount:</p>
                <v-spacer></v-spacer>
                <p class="mr-5 ">{{ orderinfo.pos_additional_discount }}</p>
              </v-col>
              <v-col class="d-flex flex-row pa-0 " style="padding-top: 0">
                <p class="font-weight-medium text-right">VAT:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{orderinfo.vat}}</p>
              </v-col>
              <v-col class="d-flex flex-row pa-0" style="padding-top: 0">
                <p class="font-weight-medium mb-0">Grand Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.grand_total }}</p>
              </v-col> -->
            </v-col>
          </v-row>
            </v-card>

          <v-row class="ml-10 d-flex flex-row">
            <v-col cols="12">
              <p class="font-weight-medium"></p>
              <p class="text-caption">
                This Invoice Is Autogenareted By {{ companyinfo.name }} After
                Confirming Your Order And Payment
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    invoiceinfo: {},
    orders: [],
    orderinfo: {},
    bill: [],
    specifications: [],
    companyinfo: {},
    snackbar: "",
    invoicearray: [],
    // headers: [
    //   {
    //     text: "Name",
    //     align: "start",
    //     sortable: false,
    //     value: "product_name",
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Color",
    //     value: "product_color",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Size",
    //     value: "product_size",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: " Unit",
    //     value: "product_unit",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },

    //   {
    //     text: "Quantity ",
    //     value: "total_quantity",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Unit Price ",
    //     value: "unit_price",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Discount ",
    //     value: "total_discount",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Amount",
    //     value: "total_price",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    // ],
  }),
  methods: {
    initialize(item) {
      axios
        .get(`Cart/pos_invoice/${this.$route.query.id}/`)
        .then((response) => {
          // console.log("this is all data", response.data)
          this.invoiceinfo = response.data.invoice_data;

          this.orderinfo = response.data.order_data;
          // console.log("order info ", this.orderinfo);
          this.orders = response.data.order_data.items;
          this.companyinfo = response.data.company_data;
          // console.log("table info", this.orders);
        });
    },

    // print(dataapp) {
    //   var divToPrint = document.getElementById("dataapp");

    //   var style = "<style>";
    //   style = style + "table {width: 100%;font: 17px Calibri;}";
    //   style =
    //     style +
    //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    //   style = style + "padding: 2px 3px;text-align: center;}";
    //   style = style + "</style>";

    //   var win = window.open("", "", "height=1900,width=1900");
    //   win.document.write(style); //  add the style.
    //   win.document.write(divToPrint.outerHTML);
    //   win.document.close();
    //   win.print();
    // },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
tbody tr:nth-of-type(even) {
  background-color:#E3F2FD!important;
}
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>